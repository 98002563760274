@import url(https://fonts.googleapis.com/css?family=Manjari|Staatliches&display=swap);
@import url(https://fonts.googleapis.com/css?family=Major+Mono+Display&display=swap);
body {font-family: 'Manjari', sans-serif;}

h1,h2,h3,h4,h5,h6, a, Link {font-family: 'Staatliches', cursive;}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#mobile {
  display: none;
}

#mobileline {
  display: none;
}

.App a {
  color: black;
  text-decoration: none;
}


@media screen and (max-width: 600px){
  #desktop {
    display: none;
  }

  .logo {
    text-align: center !important;
  }

  #mobileline {
    display: block;
    color: crimson;
    background-color: crimson;
  }

  #mobile {
    display: flex;
    position: fixed;
    bottom: 0;
    margin: auto;
    width:80%;
    left:8.5%;
    border: 3px solid crimson;
  }
}

#root {
  --maroon: #a5243d;
  --babyblue: #cee0dc;
  --lightpurp: #afaab9;
}

.App {
  padding: 20px;
}

.logo {
  text-align: left;
  font-size: 300%;
  width: 100%;
  margin: 10px auto;
  font-family: "Major Mono Display", monospace;
}

.logo span {
  color: var(--maroon);
}

nav {
  padding: 5px;
  background-color: var(--babyblue);
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

nav Link,
nav a {
  color: black;
  padding: 10px;
  text-transform: uppercase;
  font-size: 150%;
  text-decoration: none;
  -webkit-transition: background-color 0.4s, color 0.4s;
  transition: background-color 0.4s, color 0.4s;
}

nav Link:hover,
nav a:hover {
  background-color: var(--lightpurp);
  color: var(--maroon);
}

footer {
  text-align: center;
  text-transform: uppercase;
  font-weight: 900;
  margin: 20px;
}

@media only screen and (max-width: 400px) {
  .logo {
    font-size: 200%;
    text-align: center;
  }
}

#friends {
  text-align: center;

}

#friends > div {
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  margin: 10px auto;
  justify-content: space-between;
}

.friend {
  display: block;
  width: 150px;
}

.main {text-align: center; width: 90%; margin: 10px auto;}

.main p {font-size: 130%;}

.main img {width: 80%}

.learning {display: flex; justify-content: space-around; flex-wrap: wrap;}

h3 {text-align: center;}

.topic {width: 300px; padding: 10px; margin: 10px; text-align: center; background-color: var(--lightpurp);
    height: 380px;}

.topic img {width: 200px; height: 200px; text-align: center; object-fit: cover;}

.tech {display: flex; justify-content: space-around; flex-wrap: wrap; width: 90%; margin: 10px auto;} 

